import AppBar from '@mui/material/AppBar'
import Stack from 'components/commons/Stack'
import Toolbar from '@mui/material/Toolbar'
import { SxProps, styled } from '@mui/material/styles'

type AppBarProps = {
  istransparent?: string
}
const StyledAppBar = styled(AppBar)<AppBarProps>(
  ({ theme, istransparent }) => ({
    height: '72px',
    top: 'auto',
    bottom: 0,
    background:
      istransparent === 'true'
        ? 'rgba(0, 0, 0, 0)'
        : 'linear-gradient(180deg, #161A22 0%, rgba(22, 26, 34, 0) 100%)',
    color: theme.palette.text.primary,
    '& .MuiToolbar-root': {},
    boxShadow: 'none',
  }),
)

const StyledToolbar = styled(Toolbar)({
  display: 'block',
  padding: '16px 24px',
})

interface StackProps {
  isfixed?: string
}

const Wrapper = styled(Stack)<StackProps>(({ isfixed, theme }) => ({
  position: isfixed === 'true' ? 'fixed' : 'unset',
  bottom: '0px',
  width: '100%',
  height: isfixed === 'true' ? '64px' : 'unset',
  background:
    isfixed === 'true'
      ? `linear-gradient(180deg, rgba(20, 18, 32, 0) 0%, ${theme.palette.background.primary} 100%)`
      : 'rgba(0, 0, 0, 0)',
  '@media (min-width:480px)': {
    bottom: '0px',
    maxWidth: '480px',
    right: 'inherit',
  },
  right: 'none',
  maxWidth: '100vw',
  zIndex: 2,
}))

interface IFooterProps {
  children: any
  isTransparent?: boolean
  isFixed?: boolean
  style?: React.CSSProperties
  sx?: SxProps
  sxToolbar?: SxProps
}

function Footer({
  children,
  isTransparent = false,
  isFixed = false,
  style,
  sx,
  sxToolbar,
  ...otherProps
}: IFooterProps): JSX.Element {
  return (
    <Wrapper
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={0}
      isfixed={isFixed ? 'true' : 'false'}
    >
      <StyledAppBar
        position="sticky"
        istransparent={isTransparent ? 'true' : 'false'}
        style={style}
        {...otherProps}
        sx={sx}
      >
        <StyledToolbar sx={sxToolbar}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={0}
          >
            {children}
          </Stack>
        </StyledToolbar>
      </StyledAppBar>
    </Wrapper>
  )
}

export default Footer
