import {
  AppBar,
  IconButton,
  Link,
  Toolbar,
  styled,
  useTheme,
} from '@mui/material'

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos'
import { BaseHeaderProps } from '../interfaces'
import Button from 'components/commons/Button'
import Typography from 'components/commons/Typography'
import Stack from 'components/commons/Stack'
import Box from 'components/commons/Box'

type AppBarProps = {
  isTransparent?: boolean
  inLogin?: boolean
  fullWidth?: boolean
}

const StyledAppBar = styled(AppBar)<AppBarProps>(
  ({
    isTransparent = false,
    inLogin = false,
    fullWidth = false,
    theme,
  }) => ({
    marginTop: inLogin ? 72 : 0,
    right: 'none',
    maxWidth: '100vw',
    padding: fullWidth ? '0' : '0 16px',
    background: isTransparent ? 'transparent' : 'white',
    [theme.breakpoints.up('sm')]: {
      maxWidth: !fullWidth ? '480px' : '100vw',
      right: 'inherit',
    },
    '& .MuiBox-root': {
      width: '100%',
      textAlign: 'center',
    },
    '& .MuiTypography-root': {
      lineHeight: '23.44px',
      fontSize: '20px',
    },
  }),
)

const StyledToolBar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  svg: {
    color: theme.palette.text.primary,
  },
}))

interface IBaseHeader
  extends BaseHeaderProps,
    Omit<AppBarProps, 'isTransparent'> {
  title: string
}

function BaseHeader({
  title,
  backLink,
  buttonLabel,
  buttonLabelStyle = null,
  buttonAction = () => {},
  isTransparent = false,
  onBack,
  style,
  disabled = false,
  inLogin = false,
  fullWidth = false,
}: IBaseHeader) {
  const theme = useTheme()
  return (
    <StyledAppBar
      elevation={0}
      isTransparent={isTransparent}
      inLogin={inLogin}
      style={style}
      fullWidth={fullWidth}
    >
      <StyledToolBar disableGutters>
        {(!!onBack || !!backLink) && (
          <Stack
            width="44px"
            position="absolute"
            left="16px"
            top="auto"
          >
            {onBack ? (
              <IconButton
                sx={{
                  justifyContent: 'flex-start',
                }}
                disabled={disabled}
                onClick={onBack}
              >
                <ArrowBackIcon
                  sx={{
                    color: theme.palette.text.secondary,
                    marginLeft: '4px',
                  }}
                />
              </IconButton>
            ) : (
              <>
                {backLink && (
                  <Link href={backLink}>
                    <IconButton aria-label="back" edge="start">
                      <ArrowBackIcon
                        sx={{
                          color: theme.palette.text.secondary,
                          padding: 0,
                        }}
                      />
                    </IconButton>
                  </Link>
                )}
              </>
            )}
          </Stack>
        )}
        <Box flex={1}>
          <Typography variant="h4">{title}</Typography>
        </Box>
        {buttonLabel && (
          <Stack
            width="52px"
            position="absolute"
            right="16px"
            top="auto"
          >
            <Button
              buttonVariant="nude"
              disableElevation
              onClick={() => buttonAction()}
              sx={buttonLabelStyle}
              disabled={disabled}
            >
              {buttonLabel}
            </Button>
          </Stack>
        )}
      </StyledToolBar>
    </StyledAppBar>
  )
}

export default BaseHeader
