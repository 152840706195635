import Typography from 'components/commons/Typography'
import { styled } from '@mui/material/styles'
import GoogleIcon from 'assets/svg/login/google.svg'
import AppleIcon from 'assets/svg/login/apple.svg'
import redirect from 'helpers/redirect'
import authConfig from 'configs/auth'
import { trackEvent, getStateFromSource } from 'helpers/analytics'
import { useRouter } from 'next/router'
import { setLocalStorage } from 'helpers/local-storage'
import { setSessionStorage } from 'helpers/session-storage'
import { setUserDevice } from 'helpers/user-device'
import { TAB_STATE_SUB_NAMES } from 'constants/mixpanel/auth'
import { useStoreState } from 'stores/hooks'
import { addBreadcrumb, sendLog } from 'helpers/log'
import { skipPostRegistrationUrls } from 'constants/no-back-redirect'
import Button from 'components/commons/Button'
import { useTranslation } from 'react-i18next'
import { signInSSO } from 'services/authentication'

interface ILoginFooter {
  renderForgotPassword?: boolean
  renderSSO?: boolean
  isRegister?: boolean
}

export default function LoginFooter({
  renderForgotPassword,
  renderSSO,
}: ILoginFooter) {
  const { pathname, query } = useRouter()
  const { t } = useTranslation()
  const { currentTab } = useStoreState(
    (actions) => actions.phoneNumber,
  )
  const tabIndex = currentTab

  const handleClickGoogleSSO = async () => {
    if (skipPostRegistrationUrls.includes(String(query.ref)))
      setSessionStorage('isNewUser', 'true')
    trackEvent('click_google_sign_in', {
      state_name: getStateFromSource(pathname),
      state_sub_name: TAB_STATE_SUB_NAMES[tabIndex],
    })
    setUserDevice()
    setLocalStorage('authMethod', 'google')
    try {
      const response = await signInSSO('google')
      if (response?.data?.code === 'SUCCESS') {
        setLocalStorage(
          'session_id',
          response?.data?.data?.session_id,
        )
        redirect(response?.data?.data?.url)
      }
    } catch (error) {
      addBreadcrumb('auth', 'catch error login SSO', 'error', error)
      sendLog(error)
    }
  }

  const handleClickAppleSSO = () => {
    setLocalStorage('authMethod', 'appleid')
    trackEvent('click_apple_id_sign_in', {
      state_name: getStateFromSource(pathname),
      state_sub_name: TAB_STATE_SUB_NAMES[tabIndex],
    })
  }

  const handleClickForgotPassword = () => {
    trackEvent('click_forget_password', {
      state_name: getStateFromSource(pathname),
      state_sub_name: TAB_STATE_SUB_NAMES[tabIndex],
    })
    redirect('/forgot-password')
  }

  return (
    <StyledContainer>
      {renderForgotPassword && tabIndex !== 0 && (
        <StyledForgotPasswordLabel
          buttonVariant="nude"
          onClick={handleClickForgotPassword}
        >
          {t('forgotPassword')}
        </StyledForgotPasswordLabel>
      )}

      {renderSSO && (
        <>
          <StyledLineText variant="h1">
            {t('stickyBottomTwoButtonOrLabel')}
          </StyledLineText>
          <StyledIconContainer
            fullWidth
            buttonVariant="filled"
            onClick={handleClickGoogleSSO}
          >
            <GoogleIcon data-testid="google-icon" />
            Google
            {authConfig.enableAppleAccount && (
              <StyledIcon onClick={handleClickAppleSSO}>
                <AppleIcon />
              </StyledIcon>
            )}
          </StyledIconContainer>
        </>
      )}
    </StyledContainer>
  )
}

const StyledContainer = styled('div')(({ theme }) => ({
  '& .MuiTypography-h1:after': {
    left: '1em',
    marginRight: '-50%',
  },
  '& .MuiTypography-h1:before': {
    right: '1em',
    marginLeft: '-50%',
  },
  '& .MuiTypography-h1:after, .MuiTypography-h1:before': {
    background: theme.palette.background.tertiery,
    content: '""',
    display: 'inline-block',
    height: '1px',
    position: 'relative',
    verticalAlign: 'middle',
    width: '50%',
    borderRadius: '100px',
  },
}))

const StyledLineText = styled(Typography)(({ theme }) => ({
  ...theme.typography.smallRegular,
  overflow: 'hidden',
  textAlign: 'center',
  color: theme.palette.text.secondary,
  fontSize: '12px !important',
}))

const StyledForgotPasswordLabel = styled(Button)({
  marginTop: '-12px',
})

const StyledIconContainer = styled(Button)(({ theme }) => ({
  ...theme.typography.normalBold,
  color: theme.palette.text.primary,
  marginTop: '24px',
  marginBottom: '50px',
  height: '44px',
  background: theme.palette.action.disabled,
  gap: '8px',
  ':focus': {
    background: theme.palette.action.disabled,
  },
  ':hover': {
    background: theme.palette.action.disabled,
    opacity: 0.6,
  },
}))

const StyledIcon = styled(Button)(({ theme }) => ({
  background: theme.palette.background.secondary,
  borderRadius: 100,
  height: '40px',
  width: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 16px',
  minWidth: 0,
  cursor: 'pointer',
}))
