import { useCallback, useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import {
  FormControl,
  IconButton,
  InputAdornment,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import { emailOptions } from 'constants/email'
import { passwordOptions } from 'constants/password'
import TextError from 'components/domains/User/TextError'

import { AuthFormProps } from '../interfaces'
import {
  StyledForm,
  StyledInputLabel,
  StyledTextField,
} from 'components/commons/Form/styled'
import ClauseInfo from '../ClauseInfo'
import { useAuthStore } from 'stores/domains/User'
import Stack from 'components/commons/Stack'
import { useTranslation } from 'react-i18next'
export interface EmailFormData {
  email: string
  password: string
  passwordConfirmation?: string
  afterSubmit?: string
}

type IAuthEmailForm = AuthFormProps<EmailFormData>

function AuthEmailForm({
  children,
  form,
  isRegister,
  ...rest
}: IAuthEmailForm) {
  const { t } = useTranslation()
  const { control, formState, getValues, setValue, trigger, watch } =
    form
  const { errors } = formState

  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false)

  const { setInputEmail, inputEmail } = useAuthStore((state) => ({
    inputEmail: state.inputEmail,
    setInputEmail: state.setInputEmail,
  }))

  const newPassword = watch('password')

  const getErrorEmail = useCallback(() => {
    switch (errors?.email?.type) {
      case 'required':
        return t('passwordRequired')
      case 'minLength':
        return t('key_email_min_3_char')
      case 'pattern':
        return t('invalidEmail')
      case 'custom':
        return errors?.email?.message
      default:
        return ''
    }
  }, [errors?.email?.type])

  const getErrorPassword = useCallback(
    (field: 'password') => {
      switch (errors[field]?.type) {
        case 'required':
          return t('passwordRequired')
        case 'minLength':
          return t('key_password_min', {
            min: passwordOptions.minLength,
          })
        case 'pattern':
          return t('key_passowrd_must_contain')
        default:
          return ''
      }
    },
    [errors?.password?.type],
  )

  const getErrorConfirmationPassword = useCallback(
    (field: 'passwordConfirmation') => {
      switch (errors[field]?.type) {
        case 'validate':
          return t('passwordNotMatch')
        default:
          return ''
      }
    },
    [errors?.password?.type, errors?.passwordConfirmation?.type],
  )

  useEffect(() => {
    trigger('passwordConfirmation')
  }, [newPassword, trigger])

  return (
    <StyledForm autoComplete="off" {...rest}>
      <Stack spacing={0.5}>
        <FormControl variant="standard" fullWidth>
          <StyledInputLabel shrink htmlFor="i-users-auth-email">
            {t('signInUsernameLabel')}
          </StyledInputLabel>
          <Controller
            name="email"
            control={control as any}
            rules={emailOptions}
            defaultValue={inputEmail}
            render={({ field }) => (
              <StyledTextField
                id="i-users-auth-email"
                placeholder={t('emailAddress')}
                type="email"
                {...field}
                onChange={(e) => {
                  setInputEmail(e.target.value)
                  setValue('email', e.target.value, {
                    shouldValidate: true,
                  })
                }}
                sx={{ marginTop: '20px' }}
              />
            )}
          />
          {errors?.email && <TextError text={getErrorEmail()} />}
        </FormControl>

        <FormControl
          sx={(theme) => ({
            '& .MuiOutlinedInput-root': {
              background: theme.palette.background.tertiery,
              border: `1px solid ${theme.palette.border.minimal}`,
              borderRadius: '6px',
            },
          })}
          variant="standard"
          fullWidth
        >
          <StyledInputLabel shrink htmlFor="i-users-auth-password">
            {t('password')}
          </StyledInputLabel>
          <Controller
            name="password"
            control={control as any}
            defaultValue={''}
            rules={passwordOptions}
            render={({ field }) => (
              <StyledTextField
                id="i-users-auth-password"
                required
                placeholder={t('password')}
                type={showPassword ? 'text' : 'password'}
                sx={{
                  input: { border: 'none' },
                  marginTop: '20px',
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                        sx={(theme) => ({
                          color: theme.palette.text.tertiery,
                        })}
                      >
                        {showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...field}
              />
            )}
          />
          {errors?.password && (
            <TextError text={getErrorPassword('password')} />
          )}
        </FormControl>
        {isRegister && (
          <FormControl
            sx={(theme) => ({
              '& .MuiOutlinedInput-root': {
                background: theme.palette.background.tertiery,
                border: `1px solid ${theme.palette.border.minimal}`,
                borderRadius: '6px',
              },
            })}
            variant="standard"
            fullWidth
          >
            <StyledInputLabel
              shrink
              htmlFor="i-users-auth-password-confirmation"
            >
              {t('confirmPassword')}
            </StyledInputLabel>
            <Controller
              name="passwordConfirmation"
              control={control as any}
              defaultValue=""
              rules={{
                validate: (value) => value === getValues('password'),
              }}
              render={({ field }) => (
                <StyledTextField
                  id="i-users-auth-password-confirmation"
                  required
                  placeholder={t('credentialFormConfirmPasswordHint')}
                  type={
                    showPasswordConfirmation ? 'text' : 'password'
                  }
                  sx={{
                    input: { border: 'none' },
                    marginTop: '20px',
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowPasswordConfirmation(
                              !showPasswordConfirmation,
                            )
                          }
                          edge="end"
                          sx={(theme) => ({
                            color: theme.palette.text.tertiery,
                          })}
                        >
                          {showPasswordConfirmation ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...field}
                />
              )}
            />
            {errors?.passwordConfirmation && (
              <TextError
                text={getErrorConfirmationPassword(
                  'passwordConfirmation',
                )}
              />
            )}
          </FormControl>
        )}

        {isRegister && <ClauseInfo />}

        {children}
      </Stack>
    </StyledForm>
  )
}

export default AuthEmailForm
