import { Tab, Tabs, styled } from '@mui/material'
import Box from 'components/commons/Box'
import LoadingButton from 'components/commons/LoadingButton'
import Typography from 'components/commons/Typography'

export const Container = styled(Box)({
  marginTop: 55,
  color: 'white',
})

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.background.tertiery}`,
}))

export const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.text.tertiery,
}))

export const StyledBox = styled(Box)(({ theme }) => ({
  '& .MuiAppBar-root': {
    right: 'inherit',
    background: 'rgba(0, 0, 0, 0)',
  },
  '& .MuiTab-root': {
    color: theme.palette.text.tertiery,
    fontWeight: 'normal',
  },
  '& .MuiTab-root.Mui-selected': {
    color: theme.palette.text.primary,
    fontWeight: '700',
  },
  '& .MuiTabs-flexContainer': {
    justifyContent: 'space-around',
  },
}))

export const StyledTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3Desk,
  color: theme.palette.text.primary,
}))

export const StyledSubtitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.mediumRegular,
  color: theme.palette.text.secondary,
  marginTop: '8px',
  marginBottom: '16px',
}))

export const StyledSubmitButton = styled(LoadingButton)(
  ({ theme }) => ({
    width: '100% !important',
    [theme.breakpoints.up('sm')]: {
      width: '420px',
    },
    height: '44px',
  }),
)
