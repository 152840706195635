export const TAB_SIGN_UP_EVENTS: Record<number, string> = {
  0: 'select_phone_sign_up_tab',
  1: 'select_email_sign_up_tab',
}

export const TAB_SIGN_IN_EVENTS: Record<number, string> = {
  0: 'select_phone_sign_in_tab',
  1: 'select_email_sign_in_tab',
}

export const TAB_STATE_SUB_NAMES: Record<number, string> = {
  0: 'phone_number',
  1: 'email',
}
