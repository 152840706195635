import authConfig from 'configs/auth'
import { getCookie } from 'helpers/cookie'
import { redirectWithCheckingSource } from 'helpers/redirector'

/**
 * Handles after login steps
 *
 * @param tokens object containts access token and refresh token value and expiries time
 * @param cb callback function, usually used to redirect to referer or main page
 * @param path cookie path
 *
 * @notes if all services already migrate to apigateway please remove cookies rll_acct and rll_reft
 */
const redirectLoggedInUser = (path = '/'): void => {
  const idToken = getCookie(authConfig.idTokenName)
  if (idToken) {
    redirectWithCheckingSource(path)
  }
}

export default redirectLoggedInUser
