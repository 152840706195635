import * as React from 'react'
import { styled } from '@mui/material/styles'
import Typography from 'components/commons/Typography'
import InfoIcon from '@mui/icons-material/Info'

const StyledContainer = styled('div')(({ theme }) => ({
  background: theme.palette.info.main,
  display: 'flex',
  alignItems: 'center',
  padding: 8,
  borderRadius: 8,
  marginTop: 16,
  marginBottom: 40,
  svg: {
    color: 'white',
    marginRight: 8,
  },
}))
const StyledDesc = styled('div')({
  color: 'white',
  p: {
    fontSize: 14,
  },
})
function IncompatibleBrowserInfo() {
  return (
    <StyledContainer>
      <InfoIcon />
      <StyledDesc>
        <Typography sx={{ fontWeight: '700' }}>
          Sign In / Sign Up menggunakan Google SSO
        </Typography>
        <Typography>
          Gunakan eksternal browser kamu dengan cara, klik icon titik
          tiga di pojok kanan atas lalu buka eksternal browser
        </Typography>
      </StyledDesc>
    </StyledContainer>
  )
}
export default IncompatibleBrowserInfo
