import { Controller } from 'react-hook-form'
import { FormControl } from '@mui/material'

import { AuthFormProps } from '../interfaces'
import TextError from 'components/domains/User/TextError'
import {
  StyledForm,
  StyledInputLabel,
  StyledTextField,
} from 'components/commons/Form/styled'
import Grid from 'components/commons/Grid'
import Stack from 'components/commons/Stack'

import ClauseInfo from '../ClauseInfo'
import {
  phoneNumberOptions,
  phoneNumberOptionsForOther,
} from 'constants/phone-number'
import { useAuthStore } from 'stores/domains/User'
import CountrySelect from '../../SelectCountryCodes'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface PhoneFormData {
  areaCode: string
  phoneNumber: string
  afterSubmit?: string
}

type IAuthPhoneForm = AuthFormProps<PhoneFormData>

function AuthPhoneForm({
  children,
  form,
  isRegister,
  ...rest
}: IAuthPhoneForm) {
  const { t } = useTranslation()
  const { control, formState, setValue } = form
  const { errors } = formState

  const { setInputPhoneNumber, inputPhoneNumber, countryData } =
    useAuthStore((state) => ({
      inputPhoneNumber: state.inputPhoneNumber,
      setInputPhoneNumber: state.setInputPhoneNumber,
      countryData: state.countryData,
    }))

  const [validationPhoneRules, setValidationPhoneRules] = useState(
    countryData.dial_code === '+62'
      ? phoneNumberOptions
      : phoneNumberOptionsForOther,
  )

  const renderErrorMessage = () => {
    const min =
      countryData.country_code === 'ID'
        ? phoneNumberOptions.minLength
        : phoneNumberOptionsForOther.minLength
    const max =
      countryData.country_code === 'ID'
        ? phoneNumberOptions.maxLength
        : phoneNumberOptionsForOther.maxLength
    switch (errors?.phoneNumber?.type) {
      case 'required':
        return t('key_phone_cannot_be_empty')
      case 'minLength':
      case 'maxLength':
        return t('phoneNumberErrorRange', {
          min: min,
          max: max,
        })
      case 'custom':
        return errors.phoneNumber.message
      case 'pattern':
      default:
        return t('inputValidPhoneNumber')
    }
  }

  return (
    <StyledForm autoComplete="off" {...rest}>
      <Stack spacing={0.5}>
        <FormControl variant="standard" fullWidth>
          <StyledInputLabel shrink htmlFor="i-users-auth-phone">
            {t('signInPhoneNumberLabel')}
          </StyledInputLabel>
          <Grid
            id="c-users-auth-phone-grid-container"
            container
            spacing={1}
            sx={{ marginTop: '15px' }}
          >
            <Grid
              id="c-users-auth-phone-areacode-grid-item"
              item
              xs={3}
              lg={2.5}
            >
              <CountrySelect
                control={control as any}
                value={countryData?.dial_code}
                onChanged={(code) => {
                  setValidationPhoneRules(
                    code === '+62'
                      ? phoneNumberOptions
                      : phoneNumberOptionsForOther,
                  )
                  setValue('areaCode', code)
                }}
              />
            </Grid>
            {errors?.areaCode && (
              <Grid
                id="c-users-auth-phone-areacode-error-grid-item"
                item
                xs={12}
              >
                {errors?.areaCode?.type === 'required' && (
                  <TextError
                    text={t('key_area_code_cannot_be_blank')}
                  />
                )}
              </Grid>
            )}
            <Grid
              id="c-users-auth-phone-grid-item"
              item
              xs={9}
              lg={9.5}
            >
              <Controller
                name="phoneNumber"
                control={control as any}
                defaultValue={inputPhoneNumber}
                rules={validationPhoneRules}
                render={({ field }) => (
                  <StyledTextField
                    id="i-users-phone"
                    placeholder={t('signInPhoneNumberHint')}
                    type="number"
                    fullWidth
                    error={errors?.phoneNumber ? true : false}
                    {...field}
                    onChange={(e) => {
                      setInputPhoneNumber(e.target.value)
                      setValue('phoneNumber', e.target.value, {
                        shouldValidate: true,
                      })
                    }}
                    onKeyDown={(e) => {
                      if (
                        e.key === 'ArrowUp' ||
                        e.key === 'ArrowDown'
                      ) {
                        e.preventDefault()
                      }
                    }}
                    onWheel={(e) => (e.target as HTMLElement).blur()}
                  />
                )}
              />
            </Grid>
            {errors?.phoneNumber && (
              <Grid
                id="c-users-auth-phone-error-grid-item"
                item
                xs={12}
              >
                <TextError text={renderErrorMessage()} />
              </Grid>
            )}
          </Grid>
        </FormControl>

        {isRegister && <ClauseInfo />}

        {children}
      </Stack>
    </StyledForm>
  )
}

export default AuthPhoneForm
