import Drawer from '@mui/material/Drawer'
import Stack from 'components/commons/Stack'
import Typography from 'components/commons/Typography'
import CloseIcon from '@mui/icons-material/Close'

import { styled } from '@mui/material/styles'
import Button from 'components/commons/Button'
import Box from 'components/commons/Box'

const StyledDrawer = styled(Drawer)({
  width: '100vw',
  '@media (min-width:480px)': {
    width: '480px',
  },
  minHeight: '20vh',
})

const StyledStack = styled(Stack)({
  width: '100vw',
  '@media (min-width:480px)': {
    width: '480px',
  },
  minHeight: '15vh',
  marginTop: '8px',
})
const DrawerHeader = styled(Stack)({
  width: '100vw',
  '@media (min-width:480px)': {
    width: '480px',
  },
  marginTop: '8px',
})
const DrawerTitleContainer = styled(Box)({
  '@media (min-width:480px)': {
    width: '280px',
  },
})
const DrawerTitle = styled(Typography)({
  color: '#FFFFFF',
  fontSize: '20px',
  lineHeight: '20px',
  fontWeight: 'bold',
  letterSpacing: '0.02em',
  marginLeft: '16px',
})
const DrawerContentContainer = styled(Box)({
  width: '85vw',
  '@media (min-width:480px)': {
    width: '420px',
  },
})
const DrawerContent = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.02em',
}))

const ActionButtonContainer = styled(Box)({
  marginBottom: '24px !important',
  alignItems: 'center',
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
})

const StyledSubmitButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: '420px',
  },
  height: '44px',
}))

const ContainerBtn = styled(Stack)({
  display: 'flex',
  width: '100%',
  padding: '0px 16px',
  flexDirection: 'row',
})

interface ILoginBottomDrawer {
  id: string
  title: string
  content: JSX.Element
  cancelLabel: string
  secondBtnLabel: string
  thirdBtnLabel: string
  showDrawer: boolean
  onCloseAction: Function
  onSubmitAction: React.MouseEventHandler
  onSecondBtnAction: React.MouseEventHandler
  submitLabel: string
  isRequesting: boolean
  hideButtonRequest: boolean
  hideHeader: boolean
  hideThirdButton: boolean
  onThirdBtnAction: React.MouseEventHandler
  LeftBtnLabel: string
  activeButton: boolean
  hideCloseButton: boolean
}
function LoginBottomDrawer({
  id,
  title,
  cancelLabel,
  content,
  showDrawer,
  onCloseAction,
  onSubmitAction,
  onSecondBtnAction,
  submitLabel,
  isRequesting,
  hideButtonRequest,
  hideThirdButton,
  onThirdBtnAction,
  thirdBtnLabel,
  hideHeader,
  secondBtnLabel,
  hideCloseButton,
}: ILoginBottomDrawer): JSX.Element {
  return (
    <StyledDrawer
      id={id}
      anchor="bottom"
      open={showDrawer}
      PaperProps={{
        sx: (theme) => ({
          width: '100vw',
          margin: '0 auto',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          [theme.breakpoints.up('md')]: {
            width: '480px',
          },
        }),
      }}
      onClose={() => onCloseAction()}
    >
      <StyledStack
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        {!hideHeader && (
          <DrawerHeader
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <DrawerTitleContainer>
              <DrawerTitle>{title}</DrawerTitle>
            </DrawerTitleContainer>
            {!hideCloseButton && (
              <Button
                buttonVariant="nude"
                onClick={() => onCloseAction()}
                sx={{
                  textTransform: 'unset',
                }}
              >
                {cancelLabel && cancelLabel !== '' ? (
                  cancelLabel
                ) : (
                  <CloseIcon />
                )}
              </Button>
            )}
          </DrawerHeader>
        )}
        <DrawerContentContainer>
          <DrawerContent>{content}</DrawerContent>
        </DrawerContentContainer>

        <ActionButtonContainer>
          {!hideButtonRequest && (
            <ContainerBtn
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              {secondBtnLabel !== '' && (
                <StyledSubmitButton
                  buttonVariant="outlined"
                  disableElevation
                  fullWidth
                  onClick={onSecondBtnAction}
                  sx={{
                    textTransform: 'unset',
                  }}
                >
                  {secondBtnLabel}
                </StyledSubmitButton>
              )}
              <StyledSubmitButton
                buttonVariant="filled"
                disableElevation
                fullWidth
                onClick={onSubmitAction}
                disabled={isRequesting}
                sx={{
                  textTransform: 'unset',
                }}
              >
                {submitLabel}
              </StyledSubmitButton>
            </ContainerBtn>
          )}
          {!hideThirdButton && (
            <ContainerBtn
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={0}
            >
              <StyledSubmitButton
                buttonVariant="nude"
                disableElevation
                fullWidth
                onClick={onThirdBtnAction}
                sx={{
                  textTransform: 'unset',
                }}
                style={{
                  marginTop: 12,
                }}
              >
                {thirdBtnLabel}
              </StyledSubmitButton>
            </ContainerBtn>
          )}
        </ActionButtonContainer>
      </StyledStack>
    </StyledDrawer>
  )
}

LoginBottomDrawer.defaultProps = {
  id: '',
  background: '',
  title: '',
  content: '',
  cancelLabel: '',
  secondBtnLabel: '',
  thirdBtnLabel: '',
  showDrawer: false,
  onCloseAction: () => {},
  onSubmitAction: () => {},
  onSecondBtnAction: () => {},
  submitLabel: '',
  isRequesting: false,
  hideButtonRequest: false,
  hideHeader: false,
  hideThirdButton: true,
  onThirdBtnAction: () => {},
  LeftBtnLabel: '',
  activeButton: false,
  hideCloseButton: false,
}

export default LoginBottomDrawer
