import Typography from 'components/commons/Typography'
import { styled } from '@mui/material/styles'

import appConfig from 'configs/app'
import { openInNewTab } from 'helpers/redirector'
import { useTranslation } from 'react-i18next'

function ClauseInfo() {
  const { t } = useTranslation()

  return (
    <StyledSubtitle id="c-users-auth-clause-info" py={1}>
      {t('signUpTextSpan1')}{' '}
      <StyledHrefSubtitle
        as="a"
        onClick={() => openInNewTab(appConfig.termsConditionUrl)}
      >
        {t('signUpTextSpan2')}
      </StyledHrefSubtitle>{' '}
      <StyledHrefSubtitle
        as="a"
        onClick={() => openInNewTab(appConfig.privacyPolicyUrl)}
      >
        {t('signUpTextSpan3')}
      </StyledHrefSubtitle>{' '}
      {t('signUpTextSpan4')}{' '}
      <StyledHrefSubtitle
        as="a"
        onClick={() => openInNewTab(appConfig.communityGuidelineUrl)}
      >
        {t('signUpTextSpan5')}
      </StyledHrefSubtitle>{' '}
      {t('signUpTextSpan6')}
    </StyledSubtitle>
  )
}

export default ClauseInfo

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '12px! important',
  lineHeight: '16px',
  color: theme.palette.text.secondary,
  letterSpacing: '0.0025em',
  display: 'inline',

  strong: {
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
}))

export const StyledHrefSubtitle = styled(StyledSubtitle)(
  ({ theme }) => ({
    fontWeight: 700,
    cursor: 'pointer',
    color: theme.palette.text.primary,
  }),
)
