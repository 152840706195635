import { AES, enc } from 'crypto-js'

const KEY = process.env.NEXT_PUBLIC_ENCRYPT_SECRET_KEY || ''

/**
 * Start encrypt a something text
 * @param {string} text
 * @returns
 */
export function encrypt(text: string): string {
  if (!text) return
  const cipherText = AES.encrypt(text, KEY)
  return cipherText.toString()
}

/**
 * Start decrypt a something encrypted
 * @param {string} text
 * @returns
 */
export function decrypt(text: string): string {
  if (!text) return
  const decrypted = AES.decrypt(text, KEY)
  return decrypted.toString(enc.Utf8)
}
